import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import * as api from "api";
import { companyState, tokenState } from "state";
import Loading from "components/Loading";
import EditCompany from "./EditCompany";

interface Props {
  id: string;
  onCompanyDeleted: (companyId: string) => void;
}

const FILE_SIZE_LIMIT = 700; // 上传文件大小限制 700KB

const Company = ({ id, onCompanyDeleted }: Props) => {
  const token = useRecoilValue(tokenState);
  const [company, setCompany] = useRecoilState(companyState(id));

  const saveCompany = async () => {
    if (!company) return;

    const { data, error } = await api.updateCompany(token)(company);

    if (data) {
      alert("保存成功!");
    }

    if (error) {
      alert(`保存失败!\n错误信息: ${error}`);
    }
  };

  const deleteCompany = async () => {
    if (!company) return;

    if (!window.confirm(`确认删除公司${company.name}?`)) return;

    const { data, error } = await api.deleteCompany(token)(company._id);

    if (data) {
      alert(`删除成功!`);
      onCompanyDeleted(company._id);
    }

    if (error) {
      alert(`删除失败!\n错误信息: ${error}`);
    }
  };

  const uploadImage = async (key: string, images: FileList) => {
    if (!company) return;

    if (Array.from(images).some((file) => file.size > FILE_SIZE_LIMIT * 1024)) {
      const error = `文件过大! 请勿超过${FILE_SIZE_LIMIT}KB.`;
      alert(`上传图片失败!\n错误信息: ${error}`);
      return;
    }

    const { data, error } = await api.uploadImage(token)(
      "company",
      company._id,
      key,
      images
    );

    if (data) {
      setCompany({
        ...company,
        [key]: data.value,
      });
    }

    if (error) {
      alert(`上传图片失败!\n错误信息: ${error}`);
    }
  };

  const deleteImage = async (key: string, index: number) => {
    if (!company) return;

    const { data, error } = await api.deleteImage(token)(
      "company",
      company._id,
      key,
      index
    );

    if (data) {
      setCompany({
        ...company,
        [key]: data.value,
      });
    }

    if (error) {
      alert(`删除图片失败!\n错误信息: ${error}`);
    }
  };

  const addWork = async (image: File, text: string) => {
    if (!company) return;

    if (image.size > FILE_SIZE_LIMIT * 1024) {
      const error = `文件过大! 请勿超过${FILE_SIZE_LIMIT}KB.`;
      alert(`上传图片失败!\n错误信息: ${error}`);
      return;
    }

    const { data, error } = await api.addCompanyWork(token)(
      company._id,
      image,
      text
    );

    if (data) {
      setCompany({
        ...company,
        works: data.works,
      });
    }

    if (error) {
      alert(`添加作品失败!\n错误信息: ${error}`);
    }
  };

  const deleteWork = async (work: CompanyWork) => {
    if (!company) return;
    const r = window.confirm("确认删除此项工作?");
    if (!r) return;

    const { data, error } = await api.deleteCompanyWork(token)(
      company._id,
      work._id
    );

    if (data) {
      setCompany({
        ...company,
        works: data.works,
      });
    }

    if (error) {
      alert(`删除作品失败!\n错误信息: ${error}`);
    }
  };

  const addJob = async () => {
    if (!company) return;

    const { data, error } = await api.newJob(token)(company._id);

    if (data) {
      setCompany({
        ...company,
        jobs: [...(company.jobs ?? []), data.job],
      });
    }

    if (error) {
      alert(`添加职位失败!\n错误信息: ${error}`);
    }
  };

  useEffect(() => {
    const loadCompany = async () => {
      if (id) {
        const { data, error } = await api.getCompany(token)(id);
        if (data) {
          setCompany({ website: "", wechatAccount: "", ...data.company });
        }

        if (error) {
          console.log(error);
        }
      }
    };
    loadCompany();
  }, [id, token, setCompany]);

  return !company ? (
    <Loading />
  ) : (
    <EditCompany
      company={company}
      onChange={setCompany}
      onSave={saveCompany}
      onDelete={deleteCompany}
      onUploadImage={uploadImage}
      onDeleteImage={deleteImage}
      onAddJob={addJob}
      onAddWork={addWork}
      onDeleteWork={deleteWork}
    />
  );
};

export default Company;
