import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import {
  FaChevronLeft,
  FaChevronRight,
  FaCopy,
  FaPlus,
  FaSearch,
  FaSync,
  FaTrashAlt,
} from "react-icons/fa";
import { JOB_DEGREE_REQUIREMENT_OPTIONS, TagLogic } from "constant";
import { allTagsState, usersState } from "state";
import copyToClipboard from "utils/copyToClipboard";
import LocationPicker from "components/LocationPicker";
import SelectTags from "./SelectTag";
import SelectOccupation from "./SelectOccupation";
import styles from "./SearchCriteria.module.scss";

const defaultCriteria: Criteria = {
  text: "",
  name: "",
  age: { min: "", max: "" },
  workingYears: { min: "", max: "" },
  gender: 0,
  expectedLocations: [],
  degree: { min: 0, max: 0 },
  major: "",
  occupations: [],
  tagLogic: 1,
  tags: [],
};

interface Props {
  onSearch: (values: Criteria) => void;
}

const stateKey = "search-user";
const SearchCriteria = ({ onSearch }: Props) => {
  const allTags = useRecoilValue(allTagsState(stateKey));
  const users = useRecoilValue(usersState(stateKey));

  const [criteria, setCriteria] = useState<Criteria>(defaultCriteria);
  const [isSearchPanelOpen, setIsSearchPanelOpen] = useState(true);
  const [isCopyDone, setIsCopyDone] = useState(false);

  const {
    text,
    name,
    age,
    workingYears,
    gender,
    expectedLocations,
    degree,
    major,
    occupations,
    tagLogic,
    tags,
  } = criteria;

  const setPropValue = <Key extends keyof Criteria>(
    name: Key,
    value: Criteria[Key]
  ) => {
    setCriteria({ ...criteria, [name]: value });
  };

  const addExpectedLocation = () => {
    setPropValue("expectedLocations", [
      ...expectedLocations,
      {
        region: "CN",
        province: "0",
        city: "0",
        district: "0",
      },
    ]);
  };

  const setExpectedLocation = (index: number, value: ILocation) => {
    setPropValue("expectedLocations", [
      ...expectedLocations.slice(0, index),
      value,
      ...expectedLocations.slice(index + 1),
    ]);
  };

  const removeExpectedLocation = (index: number) => {
    setPropValue("expectedLocations", [
      ...expectedLocations.slice(0, index),
      ...expectedLocations.slice(index + 1),
    ]);
  };

  const search = () => {
    setIsCopyDone(false);
    onSearch(criteria);
  };

  const reset = () => {
    setIsCopyDone(false);
    setCriteria(defaultCriteria);
  };

  const copyEmails = () => {
    setIsCopyDone(false);
    copyToClipboard(users.map((u) => u.email).join(";"));
    setImmediate(() => setIsCopyDone(true));
  };

  return (
    <div
      className={styles["search-panel"]}
      onKeyUp={(e) => {
        if (e.key === "Enter") search();
      }}
    >
      {isSearchPanelOpen && (
        <div className={styles.main}>
          <div className={styles.title}>查找条件</div>
          <div className={styles.criteria}>
            <div className={styles.field}>
              <div className={styles.title}>全文搜索</div>
              <div className={styles["field-content"]}>
                <input
                  type="text"
                  value={text}
                  onChange={(e) => setPropValue("text", e.target.value)}
                />
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.title}>姓名</div>
              <div className={styles["field-content"]}>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setPropValue("name", e.target.value)}
                />
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.title}>年龄</div>
              <div className={styles["field-content"]}>
                <input
                  type="text"
                  value={age.min}
                  onChange={(e) =>
                    setPropValue("age", { ...age, min: e.target.value })
                  }
                />
                &nbsp;到&nbsp;
                <input
                  type="text"
                  value={age.max}
                  onChange={(e) =>
                    setPropValue("age", { ...age, max: e.target.value })
                  }
                />
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.title}>工作年限</div>
              <div className={styles["field-content"]}>
                <input
                  type="text"
                  value={workingYears.min}
                  onChange={(e) =>
                    setPropValue("workingYears", {
                      ...workingYears,
                      min: e.target.value,
                    })
                  }
                />
                &nbsp;到&nbsp;
                <input
                  type="text"
                  value={workingYears.max}
                  onChange={(e) =>
                    setPropValue("workingYears", {
                      ...workingYears,
                      max: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.title}>性别</div>
              <div className={styles["field-content"]}>
                <label className={styles["radio-label"]}>
                  <input
                    type="radio"
                    name="gender"
                    value="1"
                    checked={gender === 1}
                    onChange={(e) => setPropValue("gender", +e.target.value)}
                  />
                  &nbsp;男
                </label>
                <label className={styles["radio-label"]}>
                  <input
                    type="radio"
                    name="gender"
                    value="2"
                    checked={gender === 2}
                    onChange={(e) => setPropValue("gender", +e.target.value)}
                  />
                  &nbsp;女
                </label>
                <label className={styles["radio-label"]}>
                  <input
                    type="radio"
                    name="gender"
                    value="0"
                    checked={gender === 0}
                    onChange={(e) => setPropValue("gender", +e.target.value)}
                  />
                  &nbsp;不限
                </label>
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.title}>期望工作地点</div>
              {expectedLocations.map((loc, index) => (
                <div key={index} className={styles["expected-location-item"]}>
                  <LocationPicker
                    value={loc}
                    onChange={(value) => setExpectedLocation(index, value)}
                  />
                  <button
                    className={styles["light-button"]}
                    onClick={() => removeExpectedLocation(index)}
                  >
                    <FaTrashAlt />
                    &nbsp;删除
                  </button>
                </div>
              ))}

              <button
                className={styles["light-button"]}
                onClick={addExpectedLocation}
              >
                <FaPlus />
                &nbsp;添加期望工作地点
              </button>
            </div>

            <div className={styles.field}>
              <div className={styles.title}>学历</div>
              <div className={styles["field-content"]}>
                <select
                  value={degree.min}
                  onChange={(e) =>
                    setPropValue("degree", { ...degree, min: +e.target.value })
                  }
                >
                  {JOB_DEGREE_REQUIREMENT_OPTIONS.map((text, index) => (
                    <option key={index} value={index}>
                      {text}
                    </option>
                  ))}
                </select>
                &nbsp;到&nbsp;
                <select
                  value={degree.max}
                  onChange={(e) =>
                    setPropValue("degree", { ...degree, max: +e.target.value })
                  }
                >
                  {JOB_DEGREE_REQUIREMENT_OPTIONS.map((text, index) => (
                    <option key={index} value={index}>
                      {text}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.title}>专业</div>
              <div className={styles["field-content"]}>
                <input
                  type="text"
                  placeholder="输入关键词"
                  value={major}
                  onChange={(e) => setPropValue("major", e.target.value)}
                />
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.title}>职业</div>
              <div className={styles["field-content"]}>
                <SelectOccupation
                  value={occupations}
                  onChange={(value) => setPropValue("occupations", value)}
                />
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.title}>标签</div>
              <div className={styles["field-content"]}>
                <div className={styles["tag-logic"]}>
                  <label className={styles["radio-label"]}>
                    <input
                      type="radio"
                      name="tag-logic"
                      value={TagLogic.Or}
                      checked={tagLogic === TagLogic.Or}
                      onChange={(e) =>
                        setPropValue("tagLogic", +e.target.value)
                      }
                    />
                    &nbsp;或
                  </label>
                  <label className={styles["radio-label"]}>
                    <input
                      type="radio"
                      name="tag-logic"
                      value={TagLogic.And}
                      checked={tagLogic === TagLogic.And}
                      onChange={(e) =>
                        setPropValue("tagLogic", +e.target.value)
                      }
                    />
                    &nbsp;且
                  </label>
                </div>
                <SelectTags
                  allTags={allTags}
                  value={tags}
                  onChange={(value) => setPropValue("tags", value)}
                />
              </div>
            </div>
          </div>
          <footer>
            <button className={styles["primary-button"]} onClick={search}>
              <FaSearch />
              &nbsp;查找
            </button>
            <div className={styles.row}>
              <button className={styles["light-button"]} onClick={reset}>
                <FaSync />
                &nbsp;重置
              </button>
              <button
                className={styles["light-button"]}
                onClick={copyEmails}
                disabled={!users.length}
              >
                <FaCopy />
                &nbsp;{isCopyDone ? "已复制" : "复制邮箱"}
              </button>
            </div>
          </footer>
        </div>
      )}

      <div
        className={styles["resize-handle"]}
        onClick={() => setIsSearchPanelOpen(!isSearchPanelOpen)}
      >
        {isSearchPanelOpen ? <FaChevronLeft /> : <FaChevronRight />}
      </div>
    </div>
  );
};

export default SearchCriteria;
